.dcb-container {
  position: relative;
  height: 100vh;
  width: 100vw;
  margin: auto;
}

.dcb-bg {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: -100vw;
  right: -100vw;
  margin: auto;
}

.dcb-welcome {
  position: absolute;
  top: 70vh;
  bottom: 10vh;
  left: 10vw;
  min-width: 40vw;
  font-family: Impact, 'Press Start 2P', cursive, 'Arial Black', 'sans serif';
  font-size: 6vh;
  font-weight: 300;
  text-align: left;
}
