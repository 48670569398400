.rpg-logo-container {
  position: relative;
  height: 100vh;
  width: 100vw;
}

.rpg-logo-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30vh;
  margin: auto;
}
