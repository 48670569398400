.social-links {
  position: absolute;
  bottom: 40px;
  right: 40px;
}

.social-icon {
  display: inline-block;
  height: 60px;
  width: 60px;
  margin-top: 30px;
  margin-right: 10px;
  cursor: pointer;
}

.social-icon-img {
  margin-top: -30px;
  margin-left: -30px;
  height: 120px;
  pointer-events: none;
}
