.projects-container {
  position: relative;
  height: 100vh;
  width: 100vw;
  margin: auto;
}

.projects-title {
  position: absolute;
  top: 10vh;
  left: 10vw;
  font-family: Impact, 'Press Start 2P', cursive, 'Arial Black', 'sans serif';
  font-size: 6vh;
  font-weight: 300;
  text-align: left;
}

.projects-rpg-404 {
  position: absolute;
  top: -100vh;
  bottom: -100vh;
  left: -100vw;
  right: -100vw;
  margin: auto;
  height: 30vh;
  border-radius: 5vh;
  transition: transform 0.2s; /* Animation */
  image-rendering: pixelated;
  cursor: pointer;
}

.projects-rpg-404:hover {
  transform: scale(1.2);
}
